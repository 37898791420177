<template>
  <div>
    <div class="page-heading">
      <h1 class="h3">Add a New Video For Traders</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="form-group">
            <label>Title</label>
            <input type="text" required placeholder="Title" class="form-control" v-model="video.title">
          </div>
          <div class="form-group">
            <label>Video URL</label>
            <input type="text" required placeholder="Video URL" class="form-control" v-model="video.video_url">
          </div>
          <div class="form-group">
            <label>Video Thumbnail</label>
            <input type="file" placeholder="Video Thumbnail" class="form-control" ref="thumbnail">
          </div>
          <div class="form-group">
            <button class="btn btn-secondary btn-sm"><i class="lni lni-save mr-2"></i>Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      video: {}
    }
  },

  methods: {
    submit() {
      this.$loader.start()
      let data = new FormData
      data.append('title', this.video.title)
      data.append('video_url', this.video.video_url)
      data.append('thumbnail', this.$refs.thumbnail.files ? this.$refs.thumbnail.files[0] : null)

      this.$axios.post('/api/v1/admin/elite-videos', data).then(response => {
        this.$router.push({ name: 'admin.elite-videos.show', params: { id: response.data.id }})
        this.$loader.stop()
      })
    }
  }
}
</script>